import { CheckoutSession, CreditPricePoint, EcommerceItem, Wallet } from "@/models/models";
import httpInstance from "./httpClient";

export const getWallets = async (email: string): Promise<Wallet[]> => {
  return (await httpInstance.get<Wallet[]>(`wallets/${email}`)).data;
};

export const getPricePoints = async (): Promise<CreditPricePoint[]> => {
  return (await httpInstance.get<CreditPricePoint[]>(`creditpricepoints/`))
    .data;
};

export const transformPricePointsToItems = (
    pricePoints: CreditPricePoint[]
) => {
    const itemList: Array<EcommerceItem> = pricePoints.map((pricePoint) => ({
        item_id: `${pricePoint.credit_type}-${pricePoint.credit_quantity}-${pricePoint.unit_amount}`,
        item_name: `${pricePoint.credit_quantity} credits`,
        item_category: pricePoint.credit_type,
        quantity: pricePoint.credit_quantity,
        currency: pricePoint.currency,
        price: pricePoint.unit_amount / 100,
        sale: pricePoint.sale
    }));

    return itemList;
};

/**
 * create a Stripe checkout session, passing along _ga cookie for Google Analytics 4
 * @param ecommerceItem object for Google Analytics 4
 * @returns URL to Stripe checkout page
 */
export const createCheckoutSession = async (
  ecommerceItem: EcommerceItem
): Promise<CheckoutSession> => {
  return (
    await httpInstance.post<CheckoutSession>(`stripe/checkout`, {
      credit_type: ecommerceItem.item_category,
      credit_quantity: ecommerceItem.quantity,
    }, {
      withCredentials: true
    })
  ).data;
};
