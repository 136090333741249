/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CheckboxState,
  initializeCheckbox,
} from "@/components/Inputs/InputCheckbox/InputCheckbox";
import {
  initializeNumber,
  NumberState,
} from "@/components/Inputs/InputNumber/InputNumber";
import {
  initializeText,
  TextState,
} from "@/components/Inputs/InputText/InputText";
import { Prompt } from "@/models/models";
import { getAvailableModels } from "@/services/models";
import { getAvailableSamplers } from "@/services/samplers";
import { getRandomStringFromArray } from "@/utils/arrays";
import { checkIfValueInEnum, filterObjectsByProperty } from "@/utils/object";
import { generateRandomNumber } from "@/utils/random";

export enum SDOption {
  Empty = "",
  Text2Image = "text2img",
  Image2Image = "img2img",
  Inpainting = "inpainting",
  ControlNet = "controlnet",
  Pix2Pix = "pix2pix",
  Upscaling = "upscaling",
  Minecraft = "minecraft",
}

export interface IDiffusionContext {
  selectedOption: SDOption;
  validSDModels: any[];
  selectedSDModel: string;
  setSelectedSDModel: (model: string) => void;
  SDSamplers: any[];
  sampler: string;
  setSampler: (sampler: string) => void;
  prompt: TextState;
  setPrompt: (value: TextState) => void;
  negativePrompt: TextState;
  setNegativePrompt: (value: TextState) => void;
  imageSize: string;
  setImageSize: (imageHeight: string) => void;
  scale: NumberState;
  setScale: (scale: NumberState) => void;
  strength: NumberState;
  setStrength: (strength: NumberState) => void;
  steps: NumberState;
  setSteps: (steps: NumberState) => void;
  amount: NumberState;
  setAmount: (amount: NumberState) => void;
  seed: TextState;
  setSeed: (seed: TextState) => void;
  randomizeSeed: CheckboxState;
  setRandomizeSeed: (randomize: CheckboxState) => void;
  embeddingPath: TextState;
  setEmbeddingPath: (embeddingPath: TextState) => void;
  useEmbedding: CheckboxState;
  setEmbedding: (lora: CheckboxState) => void;
  loraPath: TextState;
  setLoraPath: (loraPath: TextState) => void;
  useLora: CheckboxState;
  setLora: (lora: CheckboxState) => void;
  loraScale: NumberState;
  setLoraScale: (loraScale: NumberState) => void;
  buildPrompt: () => any;
  restartSDSettings: () => void;
}

const DEFAULT_NEGATIVE_PROMPT = "";

const PROMPTS = [
    "Albert Einstein",
    "A man in a purple suit wearing a top hat",
    "Deanna Troi from Star Trek: The Next Generation",
    "Mahatma Ghandi",
    "Arnold Schwarzenegger",
    "A pirate with an eyepatch",
    "Jean-Luc Picard from Star Trek: The Next Generation",
    "The WWF wrestler Hulk Hogan",
    "The Incredible Hulk",
    "A vampire in a black and purple cape",
    "Britney Spears",
    "Ronald McDonald",
    "Louis XIV, King of France",
    "Kim Kardashian",
    "Queen Elizabeth II",
    "The Incredible Hulk",
    "Hulk Hogan, the WWF wrestler",
    "A sasquatch"
];

const initialConfig = {
  model: "monadical-labs/minecraft-skin-generator-23091300",
  sampler: "PNDMScheduler",
  prompt: initializeText(getRandomStringFromArray(PROMPTS)),
  negativePrompt: initializeText(DEFAULT_NEGATIVE_PROMPT),
  size: "768x768",
  scale: initializeNumber(10),
  steps: initializeNumber(50),
  amount: initializeNumber(4),
  seed: initializeText(String(generateRandomNumber(20))),
  randomizeSeed: initializeCheckbox(true),
  strength: initializeNumber(0.5),
  embeddingPath: initializeText(String("")),
  useEmbedding: initializeCheckbox(false),
  loraPath: initializeText(String("")),
  useLora: initializeCheckbox(false),
  loraScale: initializeNumber(1.0)
};

const defaultState = {
  selectedOption: SDOption.Minecraft,
  validSDModels: [],
  selectedSDModel: initialConfig.model,
  setSelectedSDModel: () => {},
  SDSamplers: [],
  sampler: initialConfig.sampler,
  setSampler: () => {},
  prompt: initialConfig.prompt,
  setPrompt: () => {},
  negativePrompt: initialConfig.negativePrompt,
  setNegativePrompt: () => {},
  imageSize: initialConfig.size,
  setImageSize: () => {},
  scale: initialConfig.scale,
  setScale: () => {},
  strength: initialConfig.strength,
  setStrength: () => {},
  steps: initialConfig.steps,
  setSteps: () => {},
  amount: initialConfig.amount,
  setAmount: () => {},
  seed: initialConfig.seed,
  setSeed: () => {},
  randomizeSeed: initialConfig.randomizeSeed,
  setRandomizeSeed: () => {},
  embeddingPath: initialConfig.embeddingPath,
  setEmbeddingPath: () => {},
  useEmbedding: initialConfig.useEmbedding,
  setEmbedding: () => {},
  loraPath: initialConfig.loraPath,
  setLoraPath: () => {},
  useLora: initialConfig.useLora,
  setLora: () => {},
  loraScale: initialConfig.scale,
  setLoraScale: () => {},
  buildPrompt: () => {},
  restartSDSettings: () => {},
};

const DiffusionContext = createContext<IDiffusionContext>(defaultState);

const DiffusionProvider = (props: { children: ReactNode }) => {
  const router = useRouter();

  const [selectedOption, setSelectedOption] = useState<SDOption>(
    SDOption.Minecraft
  );
  // Stable Diffusion Models
  const [SDModels, setSDModels] = useState<any[]>([]);
  const [validSDModels, setValidSDModels] = useState<any[]>([]);
  const [selectedSDModel, setSelectedSDModel] = useState<string>(
    initialConfig.model
  );

  // Sampler settings
  const [SDSamplers, setSDSamplers] = useState<any[]>([]);
  const [sampler, setSampler] = useState<string>(initialConfig.sampler);

  // Common settings
  const [prompt, setPrompt] = useState<TextState>(initialConfig.prompt);
  const [negativePrompt, setNegativePrompt] = useState<TextState>(
    initialConfig.negativePrompt
  );
  const [imageSize, setImageSize] = useState<string>(initialConfig.size);
  const [scale, setScale] = useState<NumberState>(initialConfig.scale);
  const [strength, setStrength] = useState<NumberState>(initialConfig.strength);
  const [steps, setSteps] = useState<NumberState>(initialConfig.steps);
  const [amount, setAmount] = useState<NumberState>(initialConfig.amount);
  const [seed, setSeed] = useState<TextState>(initialConfig.seed);
  const [randomizeSeed, setRandomizeSeed] = useState<CheckboxState>(
    initialConfig.randomizeSeed
  );
  const [embeddingPath, setEmbeddingPath] = useState<TextState>(initialConfig.embeddingPath);
  const [useEmbedding, setEmbedding] = useState<CheckboxState>(initialConfig.useEmbedding);
  const [loraPath, setLoraPath] = useState<TextState>(initialConfig.loraPath);
  const [useLora, setLora] = useState<CheckboxState>(initialConfig.useLora);
  const [loraScale, setLoraScale] = useState<NumberState>(initialConfig.loraScale);

  useEffect(() => {
    // Fetch Stable Diffusion models
    getAvailableModels("/models").then((response) => {
      if (response.success && response.data) {
        setSDModels(response.data || []);
      }
    });

    // Fetch Stable Diffusion samplers
    getAvailableSamplers().then((response) => {
      if (response.success && response.data) {
        const samplersData = response.data || [];
        setSDSamplers(samplersData);
      }
    });
  }, []);

  // don't check path, always use minecraft models
  // useEffect(() => {
  //   const currentPath = router.pathname.split("/").pop() as SDOption;
  //   if (
  //     currentPath !== SDOption.Empty &&
  //     checkIfValueInEnum(SDOption, currentPath)
  //   ) {
  //     setSelectedOption(currentPath);
  //   } else {
  //     setSelectedOption(SDOption.Empty);
  //   }
  // }, [router.pathname]);

  useEffect(() => {
    if (SDModels.length === 0) {
      setSelectedSDModel("No models available");
      return;
    }

    const validModels = filterObjectsByProperty(
      SDModels,
      selectedOption as string,
      true
    );

    if (validModels.length > 0) {
      setValidSDModels(validModels);
      if (!validModels.find((model) => model.source === selectedSDModel)) {
        setSelectedSDModel(validModels[0].source);
      }
      setSelectedSDModel(validModels[0].source);
    }
  }, [SDModels, selectedOption]);

  const buildPrompt = (): Prompt => {
    const width = parseInt(imageSize.split("x")[1]);
    const height = parseInt(imageSize.split("x")[0]);
    return {
      prompt: prompt.value,
      model: selectedSDModel,
      sampler: sampler,
      width: width,
      height: height,
      num_inference_steps: steps.value,
      guidance_scale: scale.value,
      num_images_per_prompt: amount.value,
      generator: Number(seed.value),
      strength: strength.value,
      negative_prompt: negativePrompt.value,
      use_lora: useLora.value,
      lora_path: loraPath.value,
      use_embedding: useEmbedding.value,
      embedding_path: embeddingPath.value,
      lora_scale: loraScale.value
    };
  };

  const restartSDSettings = () => {
    if (randomizeSeed.value) {
      setSeed(initializeText(String(generateRandomNumber(20))));
    }
  };

  return (
    <DiffusionContext.Provider
      value={{
        selectedOption,
        validSDModels,
        SDSamplers,
        selectedSDModel,
        setSelectedSDModel,
        sampler,
        setSampler,
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        imageSize: imageSize,
        setImageSize: setImageSize,
        scale,
        setScale,
        strength,
        setStrength,
        steps,
        setSteps,
        amount,
        setAmount,
        seed,
        setSeed,
        randomizeSeed,
        setRandomizeSeed,
        embeddingPath,
        setEmbeddingPath,
        useEmbedding,
        setEmbedding,
        loraPath,
        setLoraPath,
        useLora,
        setLora,
        loraScale,
        setLoraScale,
        buildPrompt,
        restartSDSettings,
      }}
    >
      {props.children}
    </DiffusionContext.Provider>
  );
};

const useDiffusion = () => {
  const context = useContext(DiffusionContext);
  if (context === undefined) {
    throw new Error("useDiffusion must be used within a DiffusionProvider");
  }
  return context;
};

export { DiffusionProvider, useDiffusion };
