/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { getFileBlobFromURL } from "@/utils/images";
import { useDiffusion } from "./SDContext";
import { isGeneration } from "@/utils/common";
import { useToastContext } from "@/context/ToastContext";
import { GenerationState, Preview, Prompt } from "@/models/models";
import { CallbackStore } from "@/utils/CallbackStore";
import {
  approveGeneration,
  getGenerationPreviewImagesWithRetry,
  getGenerationResultImagesWithRetry,
  submitGenerationWithMinecraft,
} from "@/services/genrequest";

type ImagineOptions =
  | "text2img"
  | "img2img"
  | "controlnet"
  | "pix2pix"
  | "inpainting"
  | "upscaling"
  | "minecraft";

export type ImagineResult = {
  prompt: Prompt;
  images: Array<string>;
  id?: string;
};

export interface ImagineContextProps {
  isLoading: boolean;
  setImg2ImgURL: (image: string) => void;
  img2imgFile: File | null;
  setImg2imgFile: (image: File | null) => void;
  maskFile: File | null;
  setMaskFile: (image: File | null) => void;
  preview?: Preview;
  approveImage: (request: any, generationId: string, approvedImageIndex: number) => void;
  generateImages: (value: {option: ImagineOptions, prompt: string}) => void;
  generateImagesCallbackStore: CallbackStore<ImagineResult[]>;
  resultImages: Array<ImagineResult>;
  clearResults: () => void;
}

const defaultState = {
  isLoading: false,
  setImg2ImgURL: () => console.log("setImg2ImgURL"),
  img2imgFile: null,
  setImg2imgFile: () => console.log("setImg2imgFile"),
  maskFile: null,
  setMaskFile: () => console.log("setMaskFile"),
  generateImages: () => console.log("generateImages"),
  generateImagesCallbackStore: new CallbackStore<ImagineResult[]>(),
  preview: undefined,
  approveImage: () => console.log("approveImage"),
  resultImages: [],
  clearResults: () => console.log("clearResults"),
};

const ImagineContext = createContext<ImagineContextProps>(defaultState);

const ImagineProvider = (props: { children: ReactNode }) => {
  const { prompt, buildPrompt, restartSDSettings } = useDiffusion();
  const { showErrorAlert } = useToastContext();

  // Images settings
  const [img2ImgURL, setImg2ImgURL] = useState<string>("");
  const [img2imgFile, setImg2imgFile] = useState<File | null>(null);
  const [maskFile, setMaskFile] = useState<File | null>(null);

  // Image results
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<Preview | undefined>(undefined);
  const [resultImages, setResultImages] = useState<Array<ImagineResult>>([]);

  useEffect(() => {
    if (img2ImgURL) {
      getFileBlobFromURL(img2ImgURL)
        .then((file: File) => {
          setImg2imgFile(file);
          setImg2ImgURL("");
        })
        .catch((err) => {
          showErrorAlert("Error getting file from URL");
          console.log(err);
        });
    }
  }, [img2ImgURL]);

  useEffect(() => {
    maskFile && setMaskFile(null);
  }, [img2imgFile]);

  const generateImagesCallbackStore = useRef(
    new CallbackStore<ImagineResult[]>()
  ).current;

  const generateImages = async (value: {option: ImagineOptions, prompt: string}) => {
    setIsLoading(true);
    restartSDSettings();

    /** num_images_per_prompt is set in SDContext as amount */
    const config = buildPrompt();
    const request = { prompt, ...config };
    const response = await submitGenerationWithMinecraft(request);
    if (!isGeneration(response)) {
      handleGenerationError(response.message || "Error generating image");
      return;
    }

    const generation = response;
    if (
      generation.generation_state != GenerationState.created &&
      generation.generation_state != GenerationState.preview_generation_complete
    ) {
      handleGenerationError("Generation request is in an invalid state");
      return;
    }

    const preview = await getGenerationPreviewImagesWithRetry(generation.id);
    if (!isGeneration(preview)) {
      handleGenerationError(preview.message || "Error generating image");
      return
    }

    setPreview({
      request,
      generationId: generation.id,
      images: preview.preview_images
    })
  }

  /** Choose one of the preview images to save */
  const approveImage = async (request: any, generationId: string, approvedImageIndex: number) => {
    const responseApprove = await approveGeneration(generationId, approvedImageIndex);
    if (!isGeneration(responseApprove)) {
      handleGenerationError(responseApprove.message || "An error occurred while marking the request as approved");
      return;
    }

    const responseModel = await getGenerationResultImagesWithRetry(
      generationId
    );
    
    if (!isGeneration(responseModel)) {
      handleGenerationError(responseModel.message || "An error occurred while fetching the images");  
      return;
    }

    setPreview(undefined)
    setIsLoading(false);
    // TODO: Refactor appendResults not to depend on array indices
    appendResults(request, [
      ...responseModel.images,
      ...responseModel.preview_images,
    ], resultImages);
  }

  const handleGenerationError = (errorMessage: string) => {
      setIsLoading(false);
      setPreview(undefined);
      showErrorAlert(errorMessage);
  }

  const appendResults = (request: any, images: string[], currentResults: ImagineResult[]) => {
    if (images.length === 0) return;

    const newResult: ImagineResult = {
      prompt: request,
      images: images || [],
    };
    const updatedResults = [newResult, ...currentResults];

    setResultImages(updatedResults);
    generateImagesCallbackStore.invokeAll(updatedResults);
  };

  const clearResults = () => {
    setResultImages([]);
  };

  return (
    <ImagineContext.Provider
      value={{
        isLoading,
        setImg2ImgURL,
        img2imgFile,
        setImg2imgFile,
        maskFile,
        setMaskFile,
        preview,
        resultImages,
        generateImages,
        approveImage,
        generateImagesCallbackStore,
        clearResults
      }}
    >
      {props.children}
    </ImagineContext.Provider>
  );
};
const useImagine = () => {
  const context = useContext(ImagineContext);
  if (context === undefined) {
    throw new Error("useImagine must be used within a ImagineProvider");
  }
  return context;
};

export { ImagineProvider, useImagine };
