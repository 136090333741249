import React, { FC, Fragment } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import "react-toastify/dist/ReactToastify.css";

import { ToastProvider } from "@/context/ToastContext";
import { AuthProvider } from "@/context/AuthContext";
import { DiffusionProvider } from "@/context/SDContext";
import { ControlNetProvider } from "@/context/CNContext";
import { ImagineProvider } from "@/context/ImagineContext";
import { FirebaseTrackingProvider } from "@/context/GoogleAnalyticsContext";
import { CreditsProvider } from "@/context/CreditsContext";
import { Toaster } from "@/components/ui/toaster";
import CookiesConsent from "@/components/CookiesConsent/CookiesConsent";
import { CookiesConsentProvider } from "@/context/CookiesConsentContext";

import "../App.scss";
import "../styles/globals.css";
import "../styles/marketing-globals.scss";

const App: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <Fragment>
      <Head>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Article",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.skingenerator.io"
          },
          "headline": "skingenerator.io - The Minecraft Skin Generator",
          "author": {
            "@type": "Organization",
            "name": "skingenerator.io"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Monadical Inc"
          },
          "datePublished": "2023-09-08"
        }`}
        </script>
        <title>skingenerator.io - The Minecraft Skin Generator</title>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#FFFFFF" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta
          name="description"
          content="Create custom skins for Minecraft with a simple text prompt quickly and easily."
          >
        </meta>
        <meta
          name="keywords"
          content="Minecraft, Minecraft skins, Minecraft skin generator, Minecraft skin creator, Minecraft skin maker, MC skin generator, Minecraft skin generator from text, Minecraft skins creator, Minecraft skins generator"
        />
        <meta name="author" content="Monadical Inc" />

        <meta
          property="og:title"
          content="skingenerator.io - The Minecraft Skin Generator"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.skingenerator.io" />

        <meta
          name="twitter:title"
          content="skingenerator.io - The Minecraft Skin Generator"
        />
        <meta name="twitter:description" content="" />
        <meta name="twitter:card" content="" />
        <meta name="twitter:image" content="" />

        <link rel="icon" type="image/png" href="/favicon/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/favicon/android-chrome-512x512.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link rel="manifest" href="/manifest.json" />
	<meta name="google-site-verification" content="razno3-_WyaoBrpJ7-quqcLWi48WXcUvi0XCFbERURM" />
      </Head>

      <CookiesConsentProvider>
        <FirebaseTrackingProvider>
          <ToastProvider>
            <AuthProvider>
              <DiffusionProvider>
                <ControlNetProvider>
                  <ImagineProvider>
                    <CreditsProvider>
                      <Component {...pageProps} />
                      <CookiesConsent />
                      <Toaster />
                    </CreditsProvider>
                  </ImagineProvider>
                </ControlNetProvider>
              </DiffusionProvider>
            </AuthProvider>
          </ToastProvider>
        </FirebaseTrackingProvider>
      </CookiesConsentProvider>
    </Fragment>
  );
};

export default App;
