export class CallbackStore<T> {
    private callbacks: Array<(response:T) => void> = [];
  
    add(callback: (response:T) => void): void {
      this.callbacks.push(callback);
    }
  
    remove(callback: (response:T) => void): void {
      const index = this.callbacks.indexOf(callback);
      if (index !== -1) {
        this.callbacks.splice(index, 1);
      }
    }
  
    invokeAll(response:T): void {
      for (const callback of this.callbacks) {
        callback(response);
      }
    }
  }