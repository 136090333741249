import { CreditPricePoint, CreditType, Wallet } from "@/models/models";
import { getPricePoints, getWallets } from "@/services/credits";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthContext";

export interface ICreditsContext {
  wallets: Wallet[];
  pricePoints: CreditPricePoint[];
  loadWallets: () => Promise<void>;
  loadPricePoints: () => Promise<void>;
  getCreditCountByCreditType: (creditType: CreditType) => number;
  getPricePointsByCreditType: (
    creditType: CreditType | undefined
  ) => CreditPricePoint[];
}

const defaultState: ICreditsContext = {
  wallets: [],
  pricePoints: [],
  loadWallets: async () => {},
  loadPricePoints: async () => {},
  getCreditCountByCreditType: () => 0,
  getPricePointsByCreditType: () => [],
};

const CreditsContext = createContext<ICreditsContext>(defaultState);

const CreditsProvider = (props: { children: ReactNode }) => {
  const { user } = useAuth();
  const [wallets, setWallets] = useState(defaultState.wallets);
  const [pricePoints, setPricePoints] = useState(defaultState.pricePoints);

  const loadWallets = async () => {
    setWallets(user?.email ? await getWallets(user.email) : []);
  };

  const loadPricePoints = async () => {
    setPricePoints(await getPricePoints());
  };

  useEffect(() => {
    loadWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    loadPricePoints();
  }, []);

  const getCreditCountByCreditType = (creditType: CreditType): number => {
    const wallet = wallets.find((wallet) => wallet.credit_type == creditType);
    if (wallet === undefined) return 0;
    return wallet.credit_count;
  };

  const getPricePointsByCreditType = (
    creditType: CreditType | undefined
  ): CreditPricePoint[] => {
    let filteredPricePoints = pricePoints;
    if (creditType !== undefined)
      filteredPricePoints = filteredPricePoints.filter(
        (pricePoint) => pricePoint.credit_type === creditType
      );
    return filteredPricePoints;
  };

  return (
    <CreditsContext.Provider
      value={{
        wallets: wallets,
        pricePoints: pricePoints,
        loadWallets: loadWallets,
        loadPricePoints: loadPricePoints,
        getCreditCountByCreditType: getCreditCountByCreditType,
        getPricePointsByCreditType: getPricePointsByCreditType,
      }}
    >
      {props.children}
    </CreditsContext.Provider>
  );
};

const useCredits = () => {
  const context = useContext(CreditsContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};

export { CreditsProvider, useCredits };
