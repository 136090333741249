import { IconProps } from "./types";

export const CheckIcon = (props: IconProps) => (
  <svg
    width={props.width || "8"}
    height={props.height || "6"}
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.43971 0.477003L2.83337 5.083C2.8024 5.1141 2.7656 5.13877 2.72507 5.1556C2.68455 5.17243 2.64109 5.1811 2.59721 5.1811C2.55332 5.1811 2.50987 5.17243 2.46934 5.1556C2.42882 5.13877 2.39201 5.1141 2.36104 5.083L0.579707 3.3C0.548737 3.26891 0.511933 3.24424 0.471405 3.22741C0.430878 3.21057 0.387425 3.20191 0.34354 3.20191C0.299656 3.20191 0.256203 3.21057 0.215676 3.22741C0.175148 3.24424 0.138344 3.26891 0.107374 3.3C0.0762815 3.33097 0.0516107 3.36778 0.0347768 3.40831C0.0179429 3.44883 0.00927734 3.49229 0.00927734 3.53617C0.00927734 3.58005 0.0179429 3.62351 0.0347768 3.66403C0.0516107 3.70456 0.0762815 3.74137 0.107374 3.77234L1.88937 5.554C2.07736 5.74164 2.33211 5.84702 2.59771 5.84702C2.86331 5.84702 3.11806 5.74164 3.30604 5.554L7.91204 0.949003C7.94308 0.918039 7.96771 0.881255 7.98452 0.840759C8.00132 0.800262 8.00997 0.756848 8.00997 0.713003C8.00997 0.669158 8.00132 0.625744 7.98452 0.585247C7.96771 0.54475 7.94308 0.507967 7.91204 0.477003C7.88107 0.445911 7.84427 0.42124 7.80374 0.404406C7.76321 0.387572 7.71976 0.378906 7.67588 0.378906C7.63199 0.378906 7.58854 0.387572 7.54801 0.404406C7.50748 0.42124 7.47068 0.445911 7.43971 0.477003Z"
      fill={props.color || "white"}
    />
  </svg>
);