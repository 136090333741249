import { Generation, Response, ModelType, Prompt, GenerationState } from "@/models/models";
import httpInstance from "./httpClient";
import { sleep } from "@/utils/timer";
import { ErrorResponse, isGeneration } from "@/utils/common";

const MAX_RETRY_COUNT = 32;

interface GenerationResponse extends Generation {
  success: boolean;
  message?: string;
}

export const submitGenerationWithMinecraft = async (prompt: Prompt) => {
  return (await httpInstance.post<Generation | Response>(`/generations/`, {
      model_type: ModelType.minecraft,
      prompt: prompt,
  })).data;
};

export const approveGeneration = async (
  generationId: string,
  approvedImageIndex: number
) => {
  return (
    await httpInstance.post<Generation | Response>(`/generations/${generationId}/approve`, {
      approved_image_index: approvedImageIndex,
    })
  ).data;
};

export const getGeneration = async (generationId: string) => {
  return (await httpInstance.get<Generation | Response>(`/generations/${generationId}`)).data;
};

export const fetchDataWithRetry = async (
  generationId: string,
  retryCount: number,
  maxCount: number = MAX_RETRY_COUNT,
  isPreview: boolean
): Promise<GenerationResponse | Response> => {
  if (retryCount > maxCount) {
    return {
      success: false,
      message: "Failed to fetch data from server",
      images: [],
      preview_images: [],
    };
  }

  const failed_state = isPreview
    ? GenerationState.preview_generation_failed
    : GenerationState.generation_failed
  const processing_state = isPreview ? GenerationState.created : GenerationState.credits_deducted
  const completed_state = isPreview
    ? GenerationState.preview_generation_complete
    : GenerationState.generation_complete

  try {
    const generation = await getGeneration(generationId);

    if (isGeneration(generation)) {
      switch (generation.generation_state) {
        case failed_state:
          return {
            success: false,
            images: generation.images,
            preview_images: generation.preview_images,
          };
        case processing_state:
          await sleep(mapCounterToSleepTime(retryCount));
          return await fetchDataWithRetry(
            generationId,
            retryCount + 1,
            maxCount,
            isPreview
          );
        case completed_state:
          return {
            success: true,
            ...generation
          };
        default:
          return {
            success: false,
            message: "Generation request is in an invalid state",
            images: [],
            preview_images: [],
          }
      }
    } else {
      return {
        success: false,
        message: generation.message,
        images: [],
        preview_images: [],
      }
    }  
  } catch (error) {
    if (retryCount === maxCount) {
      throw new Error("Failed to fetch data from server");
    }
    await sleep(mapCounterToSleepTime(retryCount));
    return await fetchDataWithRetry(
      generationId,
      retryCount + 1,
      maxCount,
      isPreview
    );
  }
};

export const getGenerationDataWithRetry = async (
  generationId: string,
  retryCount: number = 0,
  maxCount: number = MAX_RETRY_COUNT,
  preview: boolean
) => {
  if (!generationId) {
    return {
      success: false,
      message: "No generation id provided"
    };
  }

  return await fetchDataWithRetry(generationId, retryCount, maxCount, preview);
};

export const getGenerationResultImagesWithRetry = async (
  generationId: string,
  retryCount = 0,
  maxCount: number = MAX_RETRY_COUNT
) => {
  return await getGenerationDataWithRetry(generationId, retryCount, maxCount, false);
};

export const getGenerationPreviewImagesWithRetry = async (
  generationId: string,
  retryCount = 0,
  maxCount: number = MAX_RETRY_COUNT
) => {
  return await getGenerationDataWithRetry(generationId, retryCount, maxCount, true);
};

const mapCounterToSleepTime = (counter: number) => {
  if (counter <= 5) return 5000;
  if (counter <= 10) return 10000;
  if (counter <= 20) return 30000;
  return 5000;
};
