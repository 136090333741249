export interface UserRegistration {
  name?: string;
  email: string;
  password: string;
}

export interface User {
  name: string;
  email: string;
  avatar: string;
  bio?: string;
  phone?: string;
  is_active: boolean;
}

export interface UserFirebase {
  displayName?: string | null;
  email: string;
  phoneNumber: string;
  photoURL: string;
}

export interface Collection {
  id?: string;
  name: string;
  description: string;
  image?: string;
  images?: string[];
  collaborators?: string[];
}

export interface Prompt {
  prompt: string;
  model: string;
  sampler: string;
  width: number;
  height: number;
  num_inference_steps: number;
  guidance_scale: number;
  num_images_per_prompt: number;
  generator: number;
  strength: number;
  negative_prompt: string;
  use_lora: boolean;
  lora_path: string;
  lora_scale: number;
  use_embedding: boolean;
  embedding_path: string;
}

export interface ArtWork {
  id?: string;
  title?: string;
  image: string;
  preview?: string;
  prompt?: Prompt;
  collection_id?: string;
  isSelected?: boolean;
}

export interface Model {
  id?: string;
  name: string;
  source?: string;
  description?: string;
  is_active: boolean;
  url_docs?: string;
  text2img?: boolean;
  img2img?: boolean;
  inpainting?: boolean;
  controlnet?: boolean;
  pix2pix?: boolean;
  minecraft?: boolean;
}

export interface ControlNetModel extends Model {
  type?: string;
}

export interface Sampler {
  id?: string;
  name: string;
  description?: string;
}

export enum ModelType {
  minecraft = "minecraft",
}

export enum CreditType {
  minecraft = "minecraft",
}

export interface Wallet {
  credit_type: CreditType;
  credit_count: number;
}

export interface CreditPricePoint {
  currency: string;
  unit_amount: number;
  credit_quantity: number;
  credit_type: CreditType;
  sale?: boolean;
}

export interface EcommerceItem {
  item_id: string;
  item_name: string;
  item_category: string;
  currency: string;
  quantity: number;
  price: number;
  sale?: boolean;
}

export interface CheckoutSession {
  url: string;
}

export interface Response {
  success: boolean;
  message?: string;
  error?: string;
  data?: any;
}

export enum GenerationState {
  created = "created",
  preview_generation_failed = "preview_generation_failed",
  preview_generation_complete = "preview_generation_complete",
  credits_deducted = "credits_deducted",
  generation_failed = "generation_failed",
  generation_complete = "generation_complete"
}

export interface Preview {
  request: any;
  generationId: string;
  images: Array<string>;
}

export interface Generation {
  id: string;
  generation_state: GenerationState;
  model_type: ModelType;
  images: Array<string>;
  preview_images: Array<string>;
  credit_type: CreditType;
  credit_cost: number;
  owner_id: string;
  prompt_id: string;
  created_at: Date;
  approved_at: Date;
}
